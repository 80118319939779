import * as axios from 'axios'
import PropTypes from 'prop-types'

class PawnshopService {
    constructor(){
        this.apiKey = "SJDYMNRVOT6MO1GCEU8GVC57W7ZCTNZ0"
        this.baseUrl = "https://pawnshop-admin.com/api"
    }

    getAdminUrl = () => {
        return 'http://pawnshop-admin.com'
    }

    getOrganization = () => {
        return this.callGetWithToken("/organization")
    }

    getLoanById = (loanId) => {
        return this.callGetWithToken(`/loan/${loanId}`)
    }

    getItemById = (itemId) => {
        return this.callGetWithToken(`/item/${itemId}`)
    }

    getItemByIdSync = (itemId) => {
        return JSON.parse(this.callSyncGetWithToken(`/item/${itemId}`))
    }

    findItemsOnSale = (first, size, filterBy, sortBy, sortOrder) => {
        return this.callGetWithToken(`/item/status/onsale?first=${first}&size=${size}&filterBy=${filterBy}&sortBy=${sortBy}&sortOrder=${sortOrder}`)
    }

    findCategories = () => {
        return this.callGetWithToken(`/category`)
    }

    callGetWithToken = (path) => {
        return axios.get(`${this.baseUrl}${path}`,
        {headers: {Authorization: `client-apikey:${this.apiKey}`}})
        .catch(this.handleError)
    }

    callSyncGetWithToken = (path) => {
        const request = require('sync-request');
        const response = request('GET', `${this.baseUrl}${path}`, 
        {headers: {Authorization: `client-apikey:${this.apiKey}`}})
        return response.getBody()
    }

    handleError = (err) => {
        throw err
    }
}

export default PawnshopService

export const PawnshopServicePropType = PropTypes.shape({
    getOrganization: PropTypes.fun,
    getLoanById: PropTypes.fun,
    getItemById: PropTypes.fun,
})