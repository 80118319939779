import React, {Component} from 'react'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'


class CustomFooter extends Component{

    constructor(props){
        super(props)
        this.state = {
            organization : {},            
        }
    }

    componentDidMount(){
        this.loadOrganization()
    }

    loadOrganization = () => { 
        const {service} = this.props
        service.getOrganization().then((res) => {
            this.setState({organization: res.data})
        }).catch(console.error) 
    }

    
    render(){
        const {organization} = this.state
        return (
            <footer id="footer">
                <Grid container spacing={2}>
                    <Grid item sm={6} xs={12} md={4}>
                        <Typography className='text'>
                            <b>{organization.name}</b>
                        </Typography>
                        <Typography className='text'>
                            {organization.description}
                        </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12} md={4} >
                        <Typography className='text'>
                            {organization.address}
                        </Typography>
                        <Typography className='text'>
                            Teléfono:{organization.telephone}
                        </Typography>
                    </Grid>
                    <Grid item sm={6} xs={12} md={4}>
                        <Typography className='text'>
                            <b>Contactános</b>
                        </Typography>
                        <div style={{verticalAlign:'middle', justifyContent:'right'}}>
                            {organization.fbPage && 
                            <a href={`https://www.facebook.com/${organization.fbPage}/`} 
                            target='_blank' without='true' rel="noopener noreferrer">
                                <img src='https://img.icons8.com/color/48/000000/facebook.png' 
                                alt='Facebook Logo' style={{}}/>
                            </a>
                            }
                            {organization.whatsapp && 
                            <a href={`https://wa.me/${organization.whatsapp}`} 
                            target='_blank' without='true' rel="noopener noreferrer">
                                <img src='https://img.icons8.com/color/48/000000/whatsapp.png' 
                                alt='WhatsApp Logo' style={{}}/>
                            </a>
                            }
                            {organization.fbPage && 
                                <a href={`https://m.me/${organization.fbPage}`} 
                                target='_blank' without='true' rel="noopener noreferrer">
                                    <img src='https://img.icons8.com/color/48/000000/facebook-messenger.png' 
                                    alt='FB Messenger Logo' style={{}}/>
                                </a>
                            }
                        </div>
                    </Grid>
                </Grid>
            </footer>
        )
    }
}
export default CustomFooter;
