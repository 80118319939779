import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route} from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import ItemPage from './pages/ItemPage'
import LoanPage from './pages/LoanPage'
import SearchPage from './pages/SearchPage'
import PawnshopService from './service/PawnshopService';
import CustomToolBar from './components/CustomToolBar'
import CustomerFooter from './components/CustomFooter'
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primeicons/primeicons.css';


function App() {
  const service = new PawnshopService();
  
  return (
    <Router>
      <div>
        <AppBar position="static">
          <CustomToolBar service={service}/>
        </AppBar>
        <Route path="/"
        exact
        render={(props) => <SearchPage service={service}/>}
        />
        <Route path="/item"
        exact
        render={(props) => <ItemPage service={service}/>}
        />
        <Route path="/loan"
        exact
        render={(props) => <LoanPage service={service}/>}
        />
      </div>
      <CustomerFooter service={service}/>
    </Router>
  );
}


export default App;
