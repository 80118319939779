import React, { Component } from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid'
import ImageGallery from 'react-image-gallery';
import MetaTags from 'react-meta-tags'


const mapStyle = {
    icon: {
    },
    cardGrid: {
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
    },
};

class LoanPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loanId: undefined,
            loan: undefined
        }
    }

    componentDidMount() {
        const queryString = require('query-string');
        const parsed = queryString.parse(window.location.search);

        if (parsed.id) {
            this.setState({ loanId: parsed.id })
            this.loadLoan(parsed.id)
        }
    }

    loadLoan = (loanId) => {
        const { service } = this.props
        service.getLoanById(loanId).then((res) => {
            this.setState({ loan: res.data })
        }).catch(console.error)
    }

    getLoanStatusLabel(status){
        if(status === 'ON_SALE'){
            return 'VENCIDO/A LA VENTA'
        }
        else if(status === 'UP_TO_DAY'){
            return 'AL DIA'
        }
        else if(status === 'EXPIRED'){
            return 'VENCIDO'
        }
        else if(status === 'SOLD'){
            return 'VENDIDO'
        }
        else if(status === 'WITHDRAWN'){
            return 'RETIRADO'
        }
        else{
            return 'NO DEFINIDO'
        }
    }

    render() {
        const { loanId, loan } = this.state
        const {service} = this.props
        if (!loanId) {
            return <Typography variant="h2" color='error'>
                    Empeno no indicado
                </Typography>
        }
        else if(!loan){
            return <Typography variant="h2" color='error'>
                Empeno no encontrado
            </Typography>
        }

        const metaTitle = 'Casa de Empeño - Detalle de Empeño'
        const metaDescription = 'De seguimiento a su empeño, conozca los abonos realizados '+
         'y fechas de pago actualizadas'
        return <Container maxWidth="lg" style={{marginTop:'10px'}}>
            <MetaTags>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:type" content='website'/>
                <meta property="og:image" content=''/>
            </MetaTags>
            <Grid container>
                <Grid item sm={6} xs={12} md={4} spacing={2}>
                    <Card style={mapStyle.card}>
                        <CardContent style={mapStyle.cardContent}>
                            <Typography gutterBottom variant="h5" component="h2">
                                Empeño {loan.id}
                            </Typography>
                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textSecondary'>Estado</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textPrimary'>{this.getLoanStatusLabel(loan.status)}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textSecondary'>Fecha Empeño</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textPrimary'>{loan.incomeDate}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textSecondary'>Monto Empeño</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textPrimary'>{loan.loanPrice}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textSecondary'>Monto Interes</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textPrimary'>{loan.rateAmount}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textSecondary'>Periodo</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textPrimary'>{loan.ratePeriod} días</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textSecondary'>Siguiente Pago</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textPrimary'>{loan.nextPaymentDate}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textSecondary'>Cliente</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textPrimary'>{loan.customer.name}</Typography>
                                </Grid>
                            </Grid>
                            
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={6} xs={12} md={4} spacing={3}>
                    <Card style={mapStyle.card}>
                        <CardContent style={mapStyle.cardContent}>
                            <Typography gutterBottom variant="h5" component="h2">
                                Artículo {loan.item.id}
                            </Typography>
                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textSecondary'>Descripción</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textPrimary'>{loan.item.description}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textSecondary'>Categoría</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textPrimary'>{loan.item.category.name}</Typography>
                                </Grid>
                                {loan.item.characteristics && loan.item.characteristics.length > 0  &&
                                    loan.item.characteristics.map(row => (
                                    <React.Fragment>
                                        <Grid item xs={12} sm={6}>
                                            <Typography color='textSecondary'>{row.name}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography color='textPrimary'>{row.value}</Typography>
                                        </Grid>
                                    </React.Fragment>
                                 ))}
                            </Grid>
                            {loan.item.images && loan.item.images.length > 0 &&
                                <ImageGallery items={loan.item.images.map(image =>(
                                    {
                                        original:service.getAdminUrl()+image+'&fit=200x200',
                                        thumbnail:service.getAdminUrl()+image+'&fit=30x30'
                                    }
                                    ))
                                }/>
                            }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item sm={6} xs={12} md={4} spacing={3}>
                    <Card style={mapStyle.card}>
                        <CardContent style={mapStyle.cardContent}>
                            <Typography gutterBottom variant="h5" component="h2">
                                Abonos realizados
                            </Typography>
                            {(loan.payments === undefined || loan.payments.length === 0) &&
                            <Typography color='error'>
                                No hay abonos registrados
                            </Typography>
                            }
                        </CardContent>
                    </Card>
                </Grid>

            </Grid>
        </Container>
    }
}

export default LoanPage