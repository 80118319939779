import React, {Component} from 'react'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'


class CustomToolBar extends Component{

    constructor(props){
        super(props)
        this.state = {
            organization : {},            
        }
    }

    componentDidMount(){
        this.loadOrganization()
    }

    loadOrganization = () => { 
        const {service} = this.props
        service.getOrganization().then((res) => {
            this.setState({organization: res.data})
        }).catch(console.error) 
    }

    
    render(){
        const {organization} = this.state
        return (
            <div id="Header">
            <Toolbar>
                <Typography variant="h4" noWrap>
                    {organization.name}
                </Typography>
            </Toolbar>
            </div>
        )
    }
}
export default CustomToolBar;
