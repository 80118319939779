import React, {Component} from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import SearchBar from 'material-ui-search-bar'
import {DataView} from 'primereact/dataview';
import {Link} from 'react-router-dom'
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import MetaTags from 'react-meta-tags'

const mapStyle = {
    icon: {
    },
    cardGrid: {
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
    },
};

class SearchPage extends Component{

    constructor() {
        super();
        this.state = { 
            searchText: "",
            dataView: {
                data: [],
                totalCount:0,
                first:0,
                rows:10,
                layout: 'list',
                filterBy:"",
            },
        };
        this.itemTemplate = this.itemTemplate.bind(this);
    }


    /**
     * Accion de la barra de busqueda
     */
    onSearch = () =>{
        this.search(this.state.searchText,0)
    }

    /**
     * Accion de cancelar la busqueda
     */
    clearSearch = () =>{
        this.setState({searchText:""})
        this.search("",0)
    }

    /**
     * Accion cuando cambia de pagina
     */
    onPage = (event) =>  {
        const {dataView} = this.state
        this.search(dataView.filterBy, event.first)
    }

    search(text, first){
        const { service } = this.props
        const {rows} = this.state.dataView
        var dataView = {...this.state.dataView}
        console.log("searching:"+first)
        service.findItemsOnSale(first, rows, text, 'description', 'ASC')
            .then(res => {
                dataView.data = res.data.data
                dataView.totalCount = res.data.count
                dataView.filterBy = text
                this.setState({dataView})
            },() => console.log(dataView)).catch(console.error)
    }

    itemTemplate(varItem, layout) {
        const {service} = this.props
        if (layout === 'list') {
            return (
                <div className="p-grid">
                    {varItem && varItem != null &&
                     <Card style={mapStyle.card}>
                        <CardContent style={mapStyle.cardContent}>
                            <Grid container>
                                <Grid item lg={2}>
                                    {varItem.images && varItem.images.length > 0 &&
                                        <img src={service.getAdminUrl()+varItem.images[0]+'&size=small'} 
                                        alt='Imagen de Artículo'/>
                                    }
                                </Grid>
                                <Grid item lg={10} >
                                    <Link to={`/item?id=${varItem.id}`} item={varItem}><Typography variant='body1'>{varItem.id}</Typography></Link>
                                    <Typography variant='body1'>{varItem.description}</Typography>
                                    <Typography variant='body1'>Categoría: {varItem.category.name}</Typography>
                                    <Typography variant='body1'>Precio: {varItem.sellPrice}</Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                    }
                </div>
            );
        }
        if (layout === 'grid') {
            return (
                <div className="p-col-12 p-md-3">
                    <div>{varItem.description}</div>
                </div>
            );
        }
    }

    render(){
        const {dataView} = this.state
        const metaTitle = 'Casa de Empeño - Búsqueda de Artículos en Venta'
        const metaDescription = 'Tenemos a la venta gran variedad de Artículos a buen precio. '+
        'Use nuestro buscador para encontrar ese artículo que busca y necesita. '+
        'Además puede dar seguimiento a los empeños que ud ha realizado con nosotros.'
        return <Container maxWidth="md">
            <MetaTags>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:type" content='website'/>
                <meta property="og:image" content={process.env.PUBLIC_URL + '/search_logo.png'}/>
            </MetaTags>
            <br/>
            <div className='searchSection searchTitle'>
                <Typography variant='h4'>
                    Busqueda de Artículos en Venta
                </Typography>
            </div>
            <div className='searchSection'>
                    <SearchBar 
                            value={this.state.searchText}
                            onChange={(newValue) => this.setState({searchText:newValue}) }
                            onRequestSearch={this.onSearch}
                            onCancelSearch={this.clearSearch}
                            style={{
                                margin: '0 auto',
                                maxWidth: 800,
                                width:'100%'
                            }}
                        />
            </div>

            {dataView.filterBy !== "" && 
                <div class='searchSection'>
                    <DataView value={dataView.data} 
                            layout={dataView.layout} 
                            itemTemplate={this.itemTemplate}
                            paginator={true} 
                            rows={dataView.rows} 
                            first={dataView.first} 
                            totalRecords = {dataView.totalCount}
                            onPage={(e) => this.onPage(e)}>

                    </DataView>
                </div>
            }
        </Container>
    }
}

export default SearchPage