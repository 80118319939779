import React, {Component} from 'react'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Grid from '@material-ui/core/Grid'
import ImageGallery from 'react-image-gallery'
import {
    FacebookShareButton, 
    WhatsappShareButton, 
    FacebookIcon,
    WhatsappIcon,
} from 'react-share'
import MetaTags from 'react-meta-tags'


const mapStyle = {
    icon: {
    },
    cardGrid: {
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
    },
    cardMedia: {
        paddingTop: '56.25%', // 16:9
    },
    cardContent: {
        flexGrow: 1,
    },
    footer: {
    },
};


class ItemPage extends Component{

    constructor(props) {
        super(props);
        const itemId = this.getIdFromParameter()
        var item = undefined
        if(itemId){
            item = this.loadItem(itemId)
        }
        this.state = {
            itemId: itemId,
            item: item
        }
    }

    getIdFromParameter(){
        const queryString = require('query-string');
        const parsed = queryString.parse(window.location.search);
        return parsed.id
    }

    componentDidMount() {
    }

    loadItem= (itemId) => {
        try{
            const { service} = this.props
            return service.getItemByIdSync(itemId)
        }catch(error){
            console.error(error)
        }
        
    }

    getItemStatusLabel(status){
        if(status === 'ON_LOAN'){
            return 'EMPEÑADO'
        }
        else if(status === 'AVAILABLE'){
            return 'A LA VENTA'
        }
        else if(status === 'SOLD'){
            return 'VENDIDO'
        }
        else if(status === 'WITHDRAWN'){
            return 'RETIRADO'
        }
        else{
            return 'NO DEFINIDO'
        }
    }

    render(){
        const { itemId, item } = this.state
        const {service} = this.props
        const shareUrl = window.location.href
        if (!itemId) {
            return (
                <Container maxWidth="lg" style={{padding:'20px'}}>
                    <Typography variant="h2" color='error'>
                        Artículo no indicado
                    </Typography>
                </Container>
            )

        }
        else if(!item){
            return ( 
                <Container maxWidth="lg" style={{padding:'20px'}}>
                    <Typography variant="h2" color='error'>
                        Artículo no encontrado
                    </Typography>
                </Container>
            )
        }
        
        const shareTitle = `${item.description}\nPrecio:${item.sellPrice}\nId:${item.id}`
        return (
        <Container maxWidth="lg" style={{marginTop:'10px'}}>
            <MetaTags>
                <title>{item.description}</title>
                <meta name="description" content={shareTitle} />
            </MetaTags>
            <Grid container spacing={2}>
                <Grid item sm={6} xs={12}>
                    <Card style={mapStyle.card}>
                        <CardContent style={mapStyle.cardContent}>
                            <Typography gutterBottom variant="h5" component="h2">
                                Artículo {item.id}
                            </Typography>
                            <Grid container>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textSecondary'>Descripción</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textPrimary'>{item.description}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textSecondary'>Categoría</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textPrimary'>{item.category.name}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textSecondary'>Precio Venta</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textPrimary'>{item.sellPrice}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textSecondary'>Estado</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography color='textPrimary'>{this.getItemStatusLabel(item.status)}</Typography>
                                </Grid>
                                {item.characteristics && item.characteristics.length > 0  &&
                                    item.characteristics.map(row => (
                                    <React.Fragment>
                                        <Grid item xs={12} sm={6}>
                                            <Typography color='textSecondary'>{row.name}</Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={6}>
                                            <Typography color='textPrimary'>{row.value}</Typography>
                                        </Grid>
                                    </React.Fragment>
                                 ))}
                            </Grid>
                        </CardContent>
                    </Card>
                    <Card>
                        <CardContent>
                            <Grid item xs={12} sm={6}>
                                     <Typography color='textSecondary'>Compartir</Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <div style={{display:'flex'}}>
                                        <FacebookShareButton url={shareUrl}
                                                            quote={shareTitle}>
                                            <FacebookIcon size={32} round />
                                        </FacebookShareButton>
                                        <WhatsappShareButton url={shareUrl}
                                                        title={shareTitle}>
                                            <WhatsappIcon size={32} round />
                                        </WhatsappShareButton>
                                    </div>
                                </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid id="grid_images" item sm={6} xs={12}>
                    <Card style={mapStyle.card}>
                        <CardContent style={mapStyle.cardContent}>
                            <Typography gutterBottom variant="h5" component="h2">
                                Imágenes del Artículo
                            </Typography>
                            {item.images && item.images.length > 0 &&
                                <ImageGallery items={item.images.map(image =>(
                                    {
                                        original:service.getAdminUrl()+image+'&fit=400x400',
                                        thumbnail:service.getAdminUrl()+image+'&fit=50x50'
                                    }
                                    ))
                                }/>
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Container>
        )
    }
}

export default ItemPage